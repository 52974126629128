import './App.css';
import React from 'react';
import logo from './assets/logo.png';
import API from './api/APIClient';

import Papa from 'papaparse'
import JsxParser from 'react-jsx-parser'

import zmanim_data from './data/zmanim.csv';
import holidays_data from './data/holidays.csv';
import moment from 'moment-timezone';
import 'moment/locale/he'
moment.locale('he');

const zmanimMetadata = [
  {
    key: 648970001,
    index: 'fast',
    label: 'תחילת הצום'
  },
  {
    key: 648970002,
    index: 'alot',
    label: 'עלות השחר'
  },
  {
    key: 648970003,
    index: 'misheyakir',
    label: 'ציצית ותפילין'
  },
  {
    key: 648970004,
    index: 'netz',
    label: 'נץ החמה'
  },
  {
    key: 648970005,
    index: 'sz_kriat_shma',
    label: 'ס"ז קריאת שמע'
  },
  {
    key: 648970006,
    index: 'sz_tfila',
    label: 'סוף זמן תפילה'
  },
  {
    key: 648970007,
    index: 'hatzot',
    label: 'חצות היום'
  },
  {
    key: 648970008,
    index: 'mincha',
    label: 'מנחה גדולה'
  },
  {
    key: 648970009,
    index: 'candles',
    label: 'הדלקת נרות'
  },
  {
    key: 648970010,
    index: 'sunset',
    label: 'שקיעה'
  },
  {
    key: 648970011,
    index: 'stars',
    label: 'צאת הכוכבים'
  },
  {
    key: 648970012,
    index: 'havdalah',
    label: 'מוצאי שבת'
  },
];
const dayMetadata = [`א'`, `ב'`, `ג'`, `ד'`, `ה'`, `ו'`, `ז'`, `ח'`, `ט'`,`י'`, `י"א`, `י"ב`, `י"ג`, `י"ד`, `ט"ו`, `ט"ז`, `י"ז`, `י"ח`, `י"ט`, `כ'`, `כ"א`, `כ"ב`, `כ"ג`, `כ"ד`, `כ"ה`, `כ"ו`, `כ"ז`, `כ"ח`, `כ"ט`, `ל'`];
const monthMetadata = ['תשרי', 'חשוון', 'כסלו', 'טבת', 'שבט', 'אדר', `אדר א'`, `אדר ב'`, 'ניסן', 'אייר', 'סיון', 'תמוז', 'אב', 'אלול'];
const zlCauseMetadata = ['נפטר', 'נהרג', 'נרצח', 'נפל'];
const zlSuffixMetadata = [`ז"ל`, `זצ"ל`, `הי"ד`];

export function numberToGimatria(number, withThousands = false, withGeresh = true) {
  const gimatria = {
      1: 'א',
      2: 'ב',
      3: 'ג',
      4: 'ד',
      5: 'ה',
      6: 'ו',
      7: 'ז',
      8: 'ח',
      9: 'ט',
      10: 'י',
      20: 'כ',
      30: 'ל',
      40: 'מ',
      50: 'נ',
      60: 'ס',
      70: 'ע',
      80: 'פ',
      90: 'צ',
      100: 'ק',
      200: 'ר',
      300: 'ש',
      400: 'ת',
      500: 'ך',
      600: 'ם',
      700: 'ן',
      800: 'ף',
      900: 'ץ'
  };

  if (number <= 0)
      return '';
  let str = '';
  let order = 0;
  if (!withThousands)
      number %= 1000;
  while (number) {
      let current = (number % 10) * Math.pow(10, order++);
      let append = '';
      while (current > 400) {
          append += 'ת';
          current -= 400;
      }
      if (current)
          append += gimatria[current];
      str = append + str;
      number = Math.floor(number / 10);
      if (order === 3 && number) {
          str = `'` + str;
          order = 0;
      }
  }
  str = str.replace('יה', 'טו').replace('יו', 'טז');
  if (withGeresh)
      if (str.length >= 2)
          str = str.substr(0, str.length - 1) + `"` + str.substr(-1);
      else
          str += `'`;
  return str;
}

function H(str) {
  return Math.floor(parseInt(str) / 100);
}

function M(str) {
  return parseInt(str) % 100;
}

function stringToTime(str, adaptToDST = false) {
  return (H(str) + (adaptToDST && moment.tz('Israel').isDST() ? 1 : 0)).toString().padStart(2, '0') + ':' + M(str).toString().padStart(2, '0');
}

function areArraysIntersect(arr1, arr2, predicate) {
  return arr1.some(val1 => arr2.some(val2 => predicate(val1, val2)))
};

function numbersToTime(h, m, adaptToDST = false) {
  return (h + (adaptToDST && moment.tz('Israel').isDST() ? 1 : 0)).toString().padStart(2, '0') + ':' + m.toString().padStart(2, '0');
}

async function georgianToJewish(day = moment().date(), month = moment().month() + 1, year = moment().year()) {
  let months = ['Tishrei', 'Cheshvan', 'Kislev', 'Tevet', 'Sh\'vat', 'Adar', 'Adar I', 'Adar II', 'Nisan', 'Iyyar', 'Sivan', 'Tamuz', 'Av', 'Elul'];
  let result = await fetch(`https://www.hebcal.com/converter?cfg=json&gy=${year}&gm=${month}&gd=${day}&g2h=1`)
  let json = await result.json();
  return {
    d: json.hd,
    m: months.indexOf(json.hm) + 1,
    y: json.hy
  };
}

function calculateTfilaOffset(tfila, zmanim) {
  // offset
  let offsetDirection = tfila.crfd1_offset === 648970000 ? -1 : 1;
  let h = (tfila.crfd1_relativeto ? H(zmanim[(zmanimMetadata.find(_ => _.key === tfila.crfd1_relativeto)).index]) + (moment.tz('Israel').isDST() ? 1 : 0) : 0)
    + offsetDirection * tfila.crfd1_hours;
  let m = (tfila.crfd1_relativeto ? M(zmanim[(zmanimMetadata.find(_ => _.key === tfila.crfd1_relativeto)).index]) : 0)
    + offsetDirection * tfila.crfd1_minutes;
  // round
  switch (tfila.crfd1_rounddirection) {
    case 648970000:
      m = Math.floor(m / tfila.crfd1_roundminutes) * tfila.crfd1_roundminutes;
      break;
    case 648970001:
      m = Math.ceil(m / tfila.crfd1_roundminutes) * tfila.crfd1_roundminutes;
      break;
    case 648970002:
      m = Math.round(m / tfila.crfd1_roundminutes) * tfila.crfd1_roundminutes;
      break;
    default: break;
  }
  // minimum & maximum
  if (tfila.crfd1_hoursmin !== null && tfila.crfd1_hoursmin !== null && h * 60 + m < tfila.crfd1_hoursmin * 60 + tfila.crfd1_minutesmin) {
    h = tfila.crfd1_hoursmin;
    m = tfila.crfd1_minutesmin
  }
  if (tfila.crfd1_hoursmax !== null && tfila.crfd1_hoursmax !== null && h * 60 + m > tfila.crfd1_hoursmax * 60 + tfila.crfd1_minutesmax) {
    h = tfila.crfd1_hoursmax;
    m = tfila.crfd1_minutesmax
  }
  // out of range
  if (m >= 60) {
    h++;
    m -= 60;
  }
  if (m < 0) {
    h--;
    m += 60;
  }
  if (h < 0)
    h += 24;
  h %= 24;
  return { hours: h, minutes: m };
}

const collator = new Intl.Collator(undefined, { numeric: true });

export function compareObjects(a = {}, b = {}, properties, order = []) {
  function getField(obj, prop) {
      if (typeof prop === 'string')
          return (obj[prop] ?? ' ').toString()
      if (prop.length === 1)
          return (obj[prop[0]] ?? ' ').toString()
      return getField(obj[prop[0]] ?? {}, prop.slice(1))
  }
  for (let i = 0; i < properties.length; i++) {
      let result = collator.compare(getField(a, properties[i]), getField(b, properties[i])) * (order[i] === 'DESC' ? -1 : 1)
      if (result)
          return result
  }
  return 0;
}

function calcShiur(id, classes) {
  if (!id)
      return undefined;
  const str = classes.findIndex(_ => _.crfd1_classid === id) + 1;
  return 'שיעור ' + numberToGimatria(str);
}

async function getData(date = moment().date(), month = moment().month() + 1, year = moment().year()) {
  let days = [], description = [], isNight;
  let classes = (await API.select('/crfd1_classes', 'list')).sort((a, b) => compareObjects(a, b, ['crfd1_year'], ['DESC']))
  for (let daynumber = 0; daynumber < 8; daynumber++) {
    let day = {};
    // zmanim
    day.georgian = moment({ y: year, M: month - 1, d: date }).add(daynumber, 'days');
    day.jewish = await georgianToJewish(day.georgian.date(), day.georgian.month() + 1, day.georgian.year());
    day.zmanim = (await fetch(zmanim_data).then(_ => _.text()).then(_ => Papa.parse(_, { header: true }).data))
      .find(_ => _.month === (day.georgian.month() + 1).toString() && _.day === day.georgian.date().toString())
    delete day.zmanim['month'];
    delete day.zmanim['day'];
    if (daynumber === 0)
      isNight = moment().isAfter(moment({ h: H(day.zmanim['sunset']) + (moment.tz('Israel').isDST() ? 1 : 0), m: M(day.zmanim['sunset']) }), 'minutes');
    // events
    day.events = await fetch(`https://www.hebcal.com/hebcal?v=1&cfg=json&lg=h&geo=geoname&geonameid=293825&year=${day.georgian.year()}&month=x&maj=on&min=on&mod=on&s=on&ss=on&nx=on&mf=on&b=30&m=40&c=on&d=on&o=on&i=on`)
      .then(_ => _.json())
      .then(_ => _.items.filter(_ => moment({ y: day.georgian.year(), M: day.georgian.month(), d: day.georgian.date() }).subtract(_.subcat === 'major' && (day.jewish.m === 3 || day.jewish.m === 4) ? 1 : 0, 'day').isSame(_.date, 'day')))
      .then(_ => _.map(_ => _.category === 'hebdate' ? { ..._, hebrew: `${_.hebrew} ${numberToGimatria(parseInt(_.title_orig.substr(-4)))}` } : _));
    if (daynumber <= 3) {
      day.schedule = [
        // tfilot
        ...(await API.select(`/crfd1_tfilas?$filter=statecode eq 0 and crfd1_operation eq true and ` +
          (daynumber === 0 || (daynumber === 1 && days[0].schedule.length === 0) ? `` : `(crfd1_daysinadvance ge ${daynumber}) and `) +
          `(crfd1_weekday eq null or Microsoft.Dynamics.CRM.ContainValues(PropertyName='crfd1_weekday',PropertyValues=%5B'${648970000 + day.georgian.weekday()}'%5D)) and ` +
          `(crfd1_day eq null or Microsoft.Dynamics.CRM.ContainValues(PropertyName='crfd1_day',PropertyValues=%5B'${648969999 + day.jewish.d}'%5D)) and ` +
          `(crfd1_month eq null or Microsoft.Dynamics.CRM.ContainValues(PropertyName='crfd1_month',PropertyValues=%5B'${648969999 + day.jewish.m}'%5D)) and ` +
          `(crfd1_dst eq null or crfd1_dst eq ${moment.tz('Israel').isDST() ? 648970001 : 648970000})`, 'list'))
        .map(tfila => {
          let { hours, minutes } = calculateTfilaOffset(tfila, day.zmanim);
          let start = moment().startOf('day').add(daynumber, 'days').hours(hours).minutes(minutes);
          let end = start.clone().add(tfila.crfd1_duration ?? 15, 'minutes');
          return { ...tfila, hours, minutes, crfd1_subtitle: tfila.crfd1_note, type: 'tfila', status: moment().isAfter(end) ? 'past' : moment().isSameOrAfter(start) ? 'now' : moment().isSameOrAfter(start.clone().subtract(10, 'minutes')) ? 'starting' : 'planned' };
        }),
        // shiurim
        ...(await API.select(`/crfd1_shiurs?$filter=statecode eq 0 and ${(daynumber > 1 || (daynumber === 1 && days[0].schedule.some(schedule => schedule.type === 'shiur')) ? `(crfd1_daysinadvance ge ${daynumber}) and ` : ``)}` +
          `(crfd1_weekday eq null or Microsoft.Dynamics.CRM.ContainValues(PropertyName='crfd1_weekday',PropertyValues=%5B'${648970000 + day.georgian.weekday()}'%5D))`, 'list'))
        .map(shiur => {
          let start = moment().startOf('day').add(daynumber, 'days').hours(shiur.crfd1_hours).minutes(shiur.crfd1_minutes);
          let end = start.clone().add(shiur.crfd1_duration ?? 30, 'minutes');
          return { ...shiur, hours: shiur.crfd1_hours, minutes: shiur.crfd1_minutes, type: 'shiur', status: moment().isAfter(end) ? 'past' : moment().isSameOrAfter(start) ? 'now' : moment().isSameOrAfter(start.clone().subtract(5, 'minutes')) ? 'starting' : 'planned' };
        }),
      ]
      .sort((a, b) => (a.hours * 60 + a.minutes) - (b.hours * 60 + b.minutes));
      // at the end of the day, make the next day visible
      if (daynumber === 0 && day.schedule.every(schedule => schedule.status === 'past'))
        day.schedule = [];
    } else
      day.schedule = [];
    days.push(day);
  }
  // messages
  let messages = (await API.select(`/crfd1_messages?$filter=statecode eq 0 and (crfd1_weekday eq null or Microsoft.Dynamics.CRM.ContainValues(PropertyName='crfd1_weekday',PropertyValues=%5B'${648970000 + days[0].georgian.weekday()}'%5D))&$orderby=crfd1_priority`, 'list'))
  .sort((a, b) => b.crfd1_priority === null ? -1 : a.crfd1_priority === null ? 0 : 1)
  .filter(_ => _.crfd1_hoursstart === null || (_.crfd1_hoursstart * 60 + _.crfd1_minutesstart <= moment().hours() * 60 + moment().minutes() && _.crfd1_hoursend * 60 + _.crfd1_minutesend > moment().hours() * 60 + moment().minutes()))
  .map(_ => ({ ..._, type: 'message' }));
  // birthdays
  let hebrewdate = days[isNight ? 1 : 0].events.find(_ => _.category === 'hebdate').hebrew.replaceAll(`״`, '').replaceAll(`׳`, '').split(' ');
  messages = messages
  .concat((await API.select('/systemusers?$expand=crfd1_class&$orderby=lastname,firstname', 'list'))
    .filter(_ => {
      let day = _['crfd1_birthday_day@OData.Community.Display.V1.FormattedValue'] && _['crfd1_birthday_day@OData.Community.Display.V1.FormattedValue'].replace(`'`, '').replace(`"`, '');
      let month = _['crfd1_birthday_month@OData.Community.Display.V1.FormattedValue'] && _['crfd1_birthday_month@OData.Community.Display.V1.FormattedValue'].replace(`'`, '').replace('חשוון', 'חשון').replace('סיוון', 'סיון');
      return ['תלמיד', 'אברך', 'חייל', 'רב', 'צוות'].includes(_['crfd1_status@OData.Community.Display.V1.FormattedValue'])
      && day === hebrewdate[0]
      && (
        (
          hebrewdate.length === 3
          && (
            month === hebrewdate[1]
            || (
              hebrewdate[1] === 'אדר'
              && month.split(' ')[0] === 'אדר'
            )
          )
        )
        || (
          hebrewdate[2] === 'א'
          && month === `אדר א'`
        )
        || (
          hebrewdate[2] === 'ב'
          && (
            month === 'אדר'
            || month === `אדר ב'`
          )
        )
      )
    })
    .map(_ => ({ ..._, shiur: calcShiur(_._crfd1_class_value, classes), type: 'birthday' }))
  );
  // deaths
  messages = messages.concat((await API.select('/crfd1_zls?$filter=statecode eq 0&$orderby=crfd1_year,crfd1_name', 'list'))
    .filter(_ => dayMetadata[parseInt(_.crfd1_day) - 648970000].replaceAll(`'`, '').replaceAll(`"`, '') === hebrewdate[0]
      && ((hebrewdate.length === 3 && ((hebrewdate[1] !== 'אדר' && monthMetadata[parseInt(_.crfd1_month) - 648970000].replaceAll(`'`, '').replaceAll(`"`, '') === hebrewdate[1])
        || (hebrewdate[1] === 'אדר' && monthMetadata[parseInt(_.crfd1_month) - 648970000].replaceAll(`'`, '').replaceAll(`"`, '').split(' ')[0] === 'אדר')))
        || (hebrewdate[2] === 'ב' && monthMetadata[parseInt(_.crfd1_month) - 648970000].replaceAll(`'`, '').replaceAll(`"`, '').replaceAll(`'`, '') === 'אדר ב')
        || (hebrewdate[2] === 'א' && (monthMetadata[parseInt(_.crfd1_month) - 648970000].replaceAll(`'`, '').replaceAll(`"`, '') === 'אדר' || monthMetadata[parseInt(_.crfd1_month) - 648970000].replaceAll(`'`, '').replaceAll(`"`, '') === 'אדר א'))))
    .map(_ => ({ ..._, type: 'zl' })));
  // learn
  let learn = await fetch(`https://www.sefaria.org.il/api/calendars?timezone=Asia/Jerusalem&day=${days[0].georgian.date()}&month=${days[0].georgian.month() + 1}&year=${days[0].georgian.year()}&diaspora=0&custom=ashkenazi`).then(_ => _.json()).then(_ => _.calendar_items);
  // holidays
  let holidays = await fetch(holidays_data).then(_ => _.text()).then(_ => Papa.parse(_, { header: true }).data);
  let nextHoliday = days.findIndex((day, index) => areArraysIntersect(holidays.filter(_ => _.specialShabat !== '1'), day.events, (holiday, event) => holiday.apiName === event.hebrew.replace(/\s[0-9]+/g, '')) && (isNight ? (index <= 2 && index > 0) : index <= 1));
  if (nextHoliday !== -1) {
    let holiday = holidays.find(_ => _.apiName === days[nextHoliday].events.find(_ => _.category === 'holiday').hebrew.replace(/\s[0-9]+/g, ''));
    if (nextHoliday === 0 || holiday.yaaleVeYavo === '1' || holiday.nachem === '1')
      description.push(`${holiday.name.replace(`א' ד`, nextHoliday === 0 ? `א' ד` : '')}`);
    if (nextHoliday === 1 + (isNight ? 1 : 0) && (holiday.yaaleVeYavo === '1' || holiday.nachem === '1'))
      description[description.length - 1] = `ערב ${description[description.length - 1]}`;
  }
  // shabat
  if (days[isNight ? 1 : 0].georgian.weekday() === 5 || days[isNight ? 1 : 0].georgian.weekday() === 6) {
    let nextShabat = days.findIndex((day, index) => day.events.findIndex(_ => _.category === 'parashat') >= 0 && (isNight ? index > 0 && index <= 7 : index < 7));
    if (nextShabat !== -1) {
      description.push(`שבת ${days[nextShabat].events.find(_ => _.category === 'parashat').hebrew.replace('־', '-')}`);
      if (nextShabat === 1 + (isNight ? 1 : 0))
        description[description.length - 1] = `ערב ${description[description.length - 1]}`;
    }
    // special shabat
    let nextSpecialShabat = days.findIndex((day, index) => areArraysIntersect(holidays.filter(_ => _.specialShabat === '1'), day.events, (holiday, event) => holiday.apiName === event.hebrew) && (isNight ? index > 0 && index <= 7 : index < 7));
    if (nextSpecialShabat !== -1)
      description[description.length - 1] = `${description[description.length - 1]} - ${holidays.find(_ => _.apiName === days[nextSpecialShabat].events.find(_ => _.subcat === 'shabbat').hebrew).name}`;
  }
  // rosh chodesh
  if (days[isNight ? 1 : 0].events.findIndex(_ => _.category === 'roshchodesh') !== -1)
    description.push(`${days[isNight ? 1 : 0].events.find(_ => _.category === 'roshchodesh').hebrew}`);
  if (days[isNight ? 1 : 0].events.findIndex(_ => _.category === 'roshchodesh') === -1 && days[isNight ? 2 : 1].events.findIndex(_ => _.category === 'roshchodesh') !== -1)
    description.push(`ערב ${days[isNight ? 2 : 1].events.find(_ => _.category === 'roshchodesh').hebrew}`);
  description = description.sort((a, b) => a.indexOf('ערב ') - b.indexOf('ערב '));
  description = description.join(', ');
  let extras = [];
  if (!areArraysIntersect(holidays.filter(_ => _.yaaleVeYavo === '1'), days[isNight ? 1 : 0].events, (holiday, event) => event.hebrew.replace(/\s[0-9]+/g, '') === holiday.apiName)) {
    // parasha
    let nextShabat = days.findIndex((day, index) => day.events.findIndex(_ => _.category === 'parashat') >= 0 && (isNight ? index > 0 && index <= 7 : index < 7));
    if (nextShabat !== -1)
      extras.push(days[nextShabat].events.find(_ => _.category === 'parashat').hebrew.replace('־', '-'));
    let nextSpecialShabat = days.findIndex((day, index) => areArraysIntersect(holidays.filter(_ => _.specialShabat === '1'), day.events, (holiday, event) => holiday.apiName === event.hebrew) && (isNight ? index > 0 && index <= 7 : index < 7));
    if (nextSpecialShabat !== -1)
      extras[extras.length - 1] += (` - ${holidays.find(_ => _.apiName === days[nextSpecialShabat].events.find(_ => _.subcat === 'shabbat').hebrew).name}`);
  }
  if (days[isNight ? 1 : 0].events.findIndex(_ => _.category === 'mevarchim') >= 0)
    // mevarchin
    extras.push('ברכת החודש');
  // TODO: yizkor
  if (days[isNight ? 1 : 0].jewish.m === 14 || (days[isNight ? 1 : 0].jewish.m === 1 && days[isNight ? 1 : 0].jewish.d >= 1 && days[isNight ? 1 : 0].jewish.d <= 21))
    // ledavid
    extras.push(`לדוד ה' אורי וישעי`);
  if (days[isNight ? 1 : 0].jewish.m === 1 && days[isNight ? 1 : 0].jewish.d >= 1 && days[isNight ? 1 : 0].jewish.d <= 10)
    // 10 tshuva days
    extras.push('עשרת ימי תשובה');
  if (areArraysIntersect(holidays.filter(_ => _.nachem === '1'), days[isNight ? 1 : 0].events, (holiday, event) => event.hebrew === holiday.apiName))
    // nachem
    extras.push('נחם (במנחה)');
  if (areArraysIntersect(holidays.filter(_ => _.anenu === '1'), days[isNight ? 1 : 0].events, (holiday, event) => event.hebrew === holiday.apiName))
    // anenu
    extras.push('עננו (במנחה)');
  if (days[isNight ? 1 : 0].events.some(_ => _.category === 'roshchodesh') && days[isNight ? 1 : 0].georgian.weekday() === 6)
    // shabat rosh chodesh
    extras.push('אתה יצרת');
  if (areArraysIntersect(holidays.filter(_ => _.yaaleVeYavo === '1'), days[isNight ? 1 : 0].events, (holiday, event) => event.hebrew.replace(/\s[0-9]+/g, '') === holiday.apiName)
    || days[isNight ? 1 : 0].events.some(_ => _.category === 'roshchodesh'))
    // yaale veyavo
    extras.push('יעלה ויבוא');
  if (areArraysIntersect(holidays.filter(_ => _.alHaNisim === '1'), days[isNight ? 1 : 0].events, (holiday, event) => event.hebrew === holiday.apiName))
    // al hanisim
    extras.push('על הניסים');
  // winter / summer
  let dayIndex = days[isNight ? 1 : 0].jewish.m * 100 + days[isNight ? 1 : 0].jewish.d;
  if (dayIndex > 122 && dayIndex < 915)
    extras.push('משיב הרוח ומוריד הגשם');
  if (dayIndex < 122 || dayIndex > 915)
    extras.push('מוריד הטל');
  if (dayIndex === 122)
    extras.push('תפילת גשם');
  if (dayIndex === 915)
    extras.push('תפילת טל');
  if (dayIndex >= 207 && dayIndex < 915)
    extras.push('ותן טל ומטר');
  else
    extras.push('ותן ברכה');
  if (dayIndex > 915 && dayIndex < 1106)
    extras.push(`${(Math.floor(dayIndex / 100) - 9) * 30 - (Math.floor(dayIndex / 100) === 11 ? 1 : 0) + (dayIndex % 100) - 15} לעומר`);
  return {
    days: days,
    isNight: isNight,
    description: description,
    messages: messages,
    learn: learn,
    holidays: holidays,
    extras: extras
  };
}

function App() {
  const [time, setTime] = React.useState('');
  const [data, setData] = React.useState(null);
  const currentMessage = React.useRef(null);
  const messages = React.useRef(null);
  const timeout = React.useRef(null);
  const isReady = React.useRef(false);

  const nextMessage = React.useCallback(() => {
    if (messages.current === null)
      return;
    if (messages.current.length > 0)
      if (currentMessage.current === null) 
        currentMessage.current = 0;
      else
        currentMessage.current = (currentMessage.current + 1) % messages.current.length;
    else
      currentMessage.current = null;
    timeout.current = setTimeout(() => nextMessage(), messages.current.length > 1 ? (messages.current[currentMessage.current].crfd1_duration || 6) * 1000 : 60000);
  }, [])
  const init = React.useCallback(async () => {
    try { setData(await getData()); }
    catch (error) { }
  }, []);

  React.useEffect(() => {
    setInterval(() => {
      setTime(moment().format('HH:mm:ss'));
      if (moment().seconds() === 0)
        init();
    }, 1000);
    init();
    nextMessage();
  }, [init, nextMessage]);
  
  React.useEffect(() => {
    if (data === null)
      return;
    messages.current = data.messages;
    if (isReady.current === false) {
      nextMessage();
      isReady.current = true;
    }
  }, [data, nextMessage]);

  return isReady.current ?
    <div className='App' dir='rtl'>
      <div className='row' style={{ flex: 1 }}>
        <div className='container' style={{ flex: 1, justifyContent: 'stretch' }}>
          <div class='glass container' style={{ flexGrow: 1, justifyContent: 'flex-start' }}>
            <h1>זמני היום{data.isNight/* && ` (ליום ${moment(data.days[1].georgian).format('dddd')})`*/}</h1>
            {(() => {
              const isFast = () => areArraysIntersect(data.holidays.filter(_ => _.anenu === '1' && _.nachem !== '1'), data.days[data.isNight ? 1 : 0].events, (holiday, event) => holiday.apiName === event.hebrew);
              let zmanim = Object.keys(data.days[data.isNight ? 1 : 0].zmanim)
                .map(key => ({
                  zman: stringToTime(data.days[data.isNight ? 1 : 0].zmanim[key], true),
                  ...zmanimMetadata.find(_ => _.index === key)
                }))
                .filter(_ => {
                  if (_.index === 'fast') return isFast();
                  if (_.index === 'stars') return isFast() || areArraysIntersect(data.holidays.filter(_ => _.anenu === '1'), data.days[data.isNight ? 1 : 0].events, (holiday, event) => event.hebrew === holiday.apiName);
                  if (_.index === 'candles' || _.index === 'havdalah')
                    return data.days[data.isNight ? 1 : 0].georgian.weekday() === 5 ||
                      data.days[data.isNight ? 1 : 0].georgian.weekday() === 6 ||
                      areArraysIntersect(data.holidays.filter(_ => _.yomTov === '1'), [...data.days[data.isNight ? 1 : 0].events, ...data.days[data.isNight ? 2 : 1].events], (holiday, event) => holiday.apiName === event.hebrew.replace(/\s[0-9]+/g, ''));
                  return true;
                });
              return zmanim.map(_ =>
                <div className='detail row'>
                  <div style={{ flex: 8, textAlign: 'start' }}>
                    {_.label
                    .replace('עלות השחר', isFast() ? 'תחילת הצום' : 'עלות השחר')
                    .replace('שקיעה', areArraysIntersect(data.holidays.filter(_ => _.anenu === '1'), data.days[(data.isNight ? 1 : 0) + 1].events, (holiday, event) => event.hebrew === holiday.apiName) ? 'תחילת הצום' : 'שקיעה')
                    .replace('צאת הכוכבים', isFast() || areArraysIntersect(data.holidays.filter(_ => _.anenu === '1'), data.days[data.isNight ? 1 : 0].events, (holiday, event) => event.hebrew === holiday.apiName) ? 'סוף הצום' : 'צאת הכוכבים')
                    .replace('שבת', data.days[data.isNight ? 1 : 0].georgian.weekday() === 5 || data.days[data.isNight ? 1 : 0].georgian.weekday() === 6 ? 'שבת' : 'חג')}
                  </div>
                  <div style={{ flex: 2 }}>
                    {_.zman}
                  </div>
                </div>
              );
            })()}
          </div>
          <div class='glass container' style={{ flexGrow: 1, justifyContent: 'flex-start' }}>
            <h1>לימוד יומי</h1>
            {['דף יומי', 'הרמב"ם היומי', '929'].map(_ => {
              let value = data.learn.find(topic => topic.title.he === _)?.displayValue?.he;
              return <div className='detail container' style={{ textAlign: 'start' }}>
                {value ? value.substr(0, value.includes('(') ? value.indexOf('(') : value.length).replaceAll(':', ', ').replaceAll('-', ' - ').replaceAll('סדר ', '') : ''}
              </div>
            })}
          </div>
          <div class='glass container' style={{ flexGrow: 1, justifyContent: 'flex-start' }}>
            <h1>הוספות לתפילה</h1>
            <div className='container' style={{ flex: 1, justifyContent: 'flex-start', textAlign: 'start' }}>
              {data.extras.map(_ =>
                <div className='detail container'>
                  {_}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='container' style={{ flex: 5 }}>
          <div className='row glass' style={{ justifyContent: 'space-between', alignItems: 'flex-start', textShadow: '2px 2px #000' }}>
            <div class='container' style={{ alignItems: 'flex-start', marginRight: 8 }}>
              <span style={{ fontSize: 90, textAlign: 'right', fontWeight: 'bold' }}>
                {(() => {
                  if (moment(data.days[0].georgian).weekday() === 6 && data.isNight)
                    return 'מוצאי שבת';
                  if ((moment(data.days[0].georgian).weekday() === 6 && !data.isNight) || (moment(data.days[0].georgian).weekday() === 5 && data.isNight))
                    return 'שבת שלום!';
                  return `יום ${moment(data.days[0].georgian).format('dddd')}`;
                })()}
              </span>
              <span style={{ fontSize: 45, textAlign: 'right', fontWeight: 'bold' }}>
                {data.isNight && 'אור ל'}{data.days[data.isNight ? 1 : 0].events.find(_ => _.category === 'hebdate').hebrew}
              </span>
              {data.description !== '' && <span style={{ fontSize: 33, textAlign: 'right' }}>{data.description}</span>}
            </div>
            <div className='container' style={{ alignItems: 'flex-end', marginLeft: 8 }}>
                <span style={{ fontSize: 90, textAlign: 'right', fontWeight: 'bold' }}>{time}</span>
                <span style={{ fontSize: 45, textAlign: 'right', fontWeight: 'bold' }}>{moment(data.days[0].georgian).format('DD/MM/YYYY')}</span>
            </div>
          </div>
          <div className='row' style={{ flex: 1, alignItems: 'stretch' }}>
            <div className='glass container' style={{ flex: 2 }}>
              <h1>הודעות</h1>
              <div className='container' style={{ flex: 1 }}>
                {(() => {
                  let message = messages.current[currentMessage.current] || { type: 'empty' };
                  switch (message.type) {
                    case 'message':
                      return <JsxParser autoCloseVoidElements jsx={message.crfd1_content.replaceAll(/font-family:.*;/g, '')} blacklistedTags={[]} blacklistedAttrs={[]} renderInWrapper={false} disableKeyGeneration />
                    case 'birthday':
                      return <div style={{ fontSize: 60, textAlign: 'center' }}>
                        מזל טוב{' '}
                        {message['crfd1_status@OData.Community.Display.V1.FormattedValue'] === 'חייל' ? <>
                          לחייל האמיץ<br />
                          <b style={{ fontSize: 90 }}>{message.fullname}{message.shiur && ` (${message.shiur.split(' ')[message.shiur.split(' ').length - 1]})`}</b><br />
                        </> : message['crfd1_status@OData.Community.Display.V1.FormattedValue'] === 'אברך' ? <>
                          לאברך החשוב<br />
                          <b style={{ fontSize: 90 }}>{message.fullname}{message.shiur && ` (${message.shiur.split(' ')[message.shiur.split(' ').length - 1]})`}</b><br />
                        </> : <>
                        <br />ל{message['crfd1_status@OData.Community.Display.V1.FormattedValue'] === 'רב' && 'רב '}<b style={{ fontSize: 90 }}>{message.fullname}{message.shiur && ` (${message.shiur.split(' ')[message.shiur.split(' ').length - 1]})`}</b><br />
                        </>}
                        לרגל יום ההולדת!
                        {message['crfd1_status@OData.Community.Display.V1.FormattedValue'] === 'חייל' && message.mobilephone && <>
                          <br />
                          <br />
                          <span style={{ fontSize: 36 }}>אחלו לו מזל טוב!</span>
                          <br/>
                          {message.mobilephone.substr(0, 3)}-{message.mobilephone.substr(3)}
                        </>}
                      </div>
                    case 'zl':
                      return <span style={{ fontSize: 36 }}>
                        הלימוד היום לעילוי נשמת<br />
                        <div style={{ marginBottom: 36 }}><b style={{ fontSize: 64 }}>{message.crfd1_name} {zlSuffixMetadata[parseInt(message.crfd1_suffix) - 648970000]}</b></div>
                        <div style={{ marginBottom: 36 }}>{message.crfd1_boger ? <>בוגר הישיבה<br />ש</> : null}
                        {zlCauseMetadata[parseInt(message.crfd1_cause) - 648970000]}{message.crfd1_gender === false ? 'ה' : ''} ב{dayMetadata[parseInt(message.crfd1_day) - 648970000]} {monthMetadata[parseInt(message.crfd1_month) - 648970000]} {message.crfd1_year}<br />
                        {message.crfd1_note}</div>
                        ת.נ.צ.ב.ה.
                      </span>
                    default:
                      return 'אין מידע להצגה';
                  }
                })()}
              </div>
            </div>
            <div className='container' style={{ flex: 1, justifyContent: 'flex-start' }}>
              <div className='glass container' style={{ flexGrow: 1 }}>
                <h1>תפילות ושיעורים</h1>
                <div className='container' style={{ flex: 1, justifyContent: 'flex-start' }}>
                  {data.days.some(day => day.schedule.length > 0)
                    ? data.days.map((day, daynumber) => (day.schedule.length > 0) &&
                      <div className='segment container'>
                        {(data.days.filter(day => day.schedule.length > 0).length > 1 || daynumber > 0) && <h2>יום {day.georgian.format('dddd')}</h2>}
                          {day.schedule
                          .sort((a, b) => (a.hours * 60 + a.minutes) - (b.hours * 60 + b.minutes))
                          .map(item => <>
                            <div className={`detail row ${item.status === 'now' ? 'blinking' : item.status === 'starting' ? 'red' : item.status === 'past' ? 'greyedout' : ''}`}>
                                <div className={item.type === 'tfila' && day.schedule.some(schedule => schedule.type === 'shiur') && 'colored bold'} style={{ marginLeft: '16px' }}>
                                  {numbersToTime(item.hours, item.minutes)}
                                </div>
                                <div className={item.type === 'tfila' && day.schedule.some(schedule => schedule.type === 'shiur') && 'colored bold'} style={{ flex: 1, textAlign: 'right' }}>
                                  <div className='row' style={{ flex: 1, textAlign: 'right' }}>
                                    <b>{item.crfd1_title}</b>
                                  </div>
                                  {(item.crfd1_location || item.crfd1_subtitle) && <div className='row' style={{ flex: 1, textAlign: 'right', fontSize: '18px', marginTop: '4px' }}>
                                    {item.crfd1_subtitle && item.crfd1_subtitle}
                                    {item.crfd1_location && item.crfd1_subtitle && <span style={{ marginRight: '8px', marginLeft: '8px' }}>·</span>}
                                    {item.crfd1_location && item.crfd1_location.replace('שיעור ', '')}
                                  </div>}
                                </div>
                            </div>
                          </>)}
                      </div>
                    )
                    : 'אין מידע להצגה'
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  : <div className="App" dir="rtl" style={{ justifyContent: 'center', alignItems: 'center' }}>
    <img src={logo} alt='Logo' style={{ height: 120, marginTop: 24 }}/>
    <p className='bold'>טוען...</p>
  </div>;
}

export default App;